import React from "react"

export default function Section6() {
  return (
      <div className="w-full py-20">
      <div className="w-full p-10 lg:p-20">
          <p className="text-5xl text-black font-bold text-center text-aileron-bold">
            Ciergio is perfect in these other industries:
          </p>
          <div className="divider bg-black my-20 mx-auto"></div>
          <div class="flex content-start flex-wrap w-5/6 mx-auto">
            
            <div class="w-full sm:w-1/2 md:w-1/4 px-2">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-corporation.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                        Corporation
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-real-estate.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                        Real Estate Property Management
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-country-club.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                    Social & Country Clubs

                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-ngo.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                    Non-Profit Organization
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2 pb-10">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-influencers.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                      Influencers
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2 pb-10">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-organization.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                      Organization & Associations
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2 pb-10">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-church.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                      Church and Parish Communities
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/4 px-2 pb-10">
                <div class="text-gray-700 text-center p-2">
                    <div className="w-full items-center">
                        <div class="rounded-full mx-auto h-110 w-110 flex items-center justify-center bg-gray-light text-grey">
                            <img 
                                className=""
                                src={require("../../../assets/images/img-alumni.png")}
                                alt=""
                                width='60%'
                            />
                        </div>
                    </div>
                    <div className="w-full text-center text-gray-800 text-lg py-10 font-bold text-aileron-bold">
                      Alumni Association
                    </div>
                </div>
            </div>
            
          </div>

      </div>
      </div>
  )
}